import { useState, useEffect, useCallback } from 'react';
import { debounce } from '../utils';

export type SaveStatus = 'saved' | 'saving' | 'error' | null;

export function useSaveStatus() {
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(null);

  useEffect(() => {
    if (saveStatus === 'saved') {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const debouncedSave = useCallback(
    (saveFn: () => Promise<void>) => {
      return debounce(async () => {
        try {
          await saveFn();
          setSaveStatus('saved');
        } catch (error) {
          console.error('Save error:', error);
          setSaveStatus('error');
        }
      }, 750)();
    },
    []
  );

  const save = (saveFn: () => Promise<void>) => {
    setSaveStatus('saving');
    debouncedSave(saveFn);
  };

  return { saveStatus, save };
} 