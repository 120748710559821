import { ColumnType } from '../../types/table';
import { Calendar, ListTodo, ArrowUpDown, Type, Ruler } from 'lucide-react';

export const COLUMN_TYPES = [
  {
    type: 'status' as ColumnType,
    label: 'Status',
    description: 'Track progress with customizable status labels',
    icon: ListTodo,
    defaultOptions: ['Not Started', 'In Progress', 'Done']
  },
  {
    type: 'date' as ColumnType,
    label: 'Due Date',
    description: 'Add dates and deadlines',
    icon: Calendar
  },
  {
    type: 'priority' as ColumnType,
    label: 'Priority',
    description: 'Set priority levels',
    icon: ArrowUpDown,
    defaultOptions: ['High', 'Medium', 'Low']
  },
  {
    type: 'text' as ColumnType,
    label: 'Text',
    description: 'Add any text information',
    icon: Type
  },
  {
    type: 'size' as ColumnType,
    label: 'Size',
    description: 'T-shirt sizes or story points',
    icon: Ruler,
    defaultOptions: ['XS', 'S', 'M', 'L', 'XL']
  }
]; 