import { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import type { KanbanTask } from '../../types/kanban';
import { useSupabase } from '../../lib/supabase/supabase-context';
import { useSaveStatus } from '../../lib/hooks/useSaveStatus';

interface TaskDetailsModalProps {
  task: KanbanTask | null;
  isOpen: boolean;
  onClose: () => void;
  onUpdate: (task: KanbanTask) => void;
  onDelete: (task: KanbanTask) => void;
}

function SaveIndicator({ status }: { status: 'saved' | 'saving' | 'error' | null }) {
  if (!status) return null;

  return (
    <div className={`flex items-center gap-1.5 text-xs font-medium transition-all duration-300
      ${status === 'saved' ? 'animate-fade-out' : 'opacity-100'}`}
    >
      {status === 'saving' ? (
        <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
          <div className="w-1.5 h-1.5 rounded-full bg-gray-400 dark:bg-gray-500 animate-pulse" />
          Saving
        </div>
      ) : status === 'saved' ? (
        <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
          <div className="w-1.5 h-1.5 rounded-full bg-emerald-400 dark:bg-emerald-500" />
          Saved
        </div>
      ) : status === 'error' ? (
        <div className="flex items-center gap-1.5 text-red-500 dark:text-red-400">
          <div className="w-1.5 h-1.5 rounded-full bg-red-500 dark:bg-red-400" />
          Failed to save
        </div>
      ) : null}
    </div>
  );
}

export function TaskDetailsModal({ task, isOpen, onClose, onUpdate, onDelete }: TaskDetailsModalProps) {
  const { supabase } = useSupabase();
  const [title, setTitle] = useState(task?.title || '');
  const [description, setDescription] = useState(task?.description || '');
  const [tags, setTags] = useState<string[]>(task?.tags || []);
  const { saveStatus } = useSaveStatus();

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDescription(task.description);
      setTags(task.tags);
    }
  }, [task]);

  const handleSave = async () => {
    if (!task) return;

    const { data, error } = await supabase
      .from('kanban_tasks')
      .update({
        title,
        description,
        tags,
        column_id: task.columnId,
        updated_at: new Date().toISOString()
      })
      .eq('id', task.id)
      .select()
      .single();

    if (error) throw error;
    if (data) {
      const updatedTask: KanbanTask = {
        ...task,
        title,
        description,
        tags,
        updatedAt: new Date(data.updated_at)
      };
      onUpdate(updatedTask);
      onClose();
    }
  };

  const handleChange = (changes: Partial<{ title: string; description: string; tags: string[] }>) => {
    if (changes.title !== undefined) setTitle(changes.title);
    if (changes.description !== undefined) setDescription(changes.description);
    if (changes.tags !== undefined) setTags(changes.tags);
  };

  if (!isOpen || !task) return null;

  return (
    <div className="fixed inset-0 bg-black/50 dark:bg-black/70 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-xl shadow-xl max-w-2xl w-full mx-4">
        <div className="flex justify-between items-start mb-4">
          <div className="flex-1 flex items-center gap-2">
            <input
              type="text"
              value={title}
              onChange={(e) => handleChange({ title: e.target.value })}
              className="text-xl font-semibold bg-transparent border-none focus:ring-2 
                        focus:ring-indigo-500 rounded w-full text-gray-900 dark:text-white"
              placeholder="Task title"
            />
            <SaveIndicator status={saveStatus} />
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 dark:text-gray-500 dark:hover:text-gray-300"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => handleChange({ description: e.target.value })}
              className="w-full rounded-md border-gray-300 dark:border-gray-600 
                        shadow-sm focus:border-indigo-500 focus:ring-indigo-500
                        bg-white dark:bg-gray-700 text-gray-900 dark:text-white"
              rows={4}
              placeholder="Add description..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Tags
            </label>
            <div className="flex flex-wrap gap-2 mb-2">
              {tags.map((tag, index) => (
                <span
                  key={index}
                  className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs 
                            font-medium bg-indigo-100 dark:bg-indigo-900 
                            text-indigo-800 dark:text-indigo-300"
                >
                  {tag}
                  <button
                    type="button"
                    onClick={() => handleChange({ tags: tags.filter((_, i) => i !== index) })}
                    className="ml-1 text-indigo-600 dark:text-indigo-400 
                              hover:text-indigo-500 dark:hover:text-indigo-300"
                  >
                    <X className="h-3 w-3" />
                  </button>
                </span>
              ))}
            </div>
            <div className="flex gap-2">
              <input
                type="text"
                placeholder="Add a tag"
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.currentTarget.value.trim()) {
                    e.preventDefault();
                    handleChange({ tags: [...tags, e.currentTarget.value.trim()] });
                    e.currentTarget.value = '';
                  }
                }}
                className="flex-1 rounded-md border-gray-300 dark:border-gray-600 
                          shadow-sm focus:border-indigo-500 focus:ring-indigo-500
                          bg-white dark:bg-gray-700 text-gray-900 dark:text-white
                          text-sm"
              />
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-between">
          <button
            onClick={() => task && onDelete(task)}
            className="px-4 py-2 text-sm font-medium text-red-600 dark:text-red-400 
                      hover:text-red-700 dark:hover:text-red-300"
          >
            Delete
          </button>
          <div className="space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-600 dark:text-gray-400 
                        hover:text-gray-700 dark:hover:text-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 
                        hover:bg-indigo-700 rounded-md dark:bg-indigo-500 
                        dark:hover:bg-indigo-400"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
} 