import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { Home } from './pages/Home';
import { Tools } from './pages/Tools';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/auth/Login';
import { Signup } from './pages/auth/Signup';
import { AuthProvider } from './lib/supabase/AuthProvider';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { ResetPassword } from './pages/auth/ResetPassword';
import { UpdatePassword } from './pages/auth/UpdatePassword';
import { Calculator } from './pages/tools/Calculator';
import { PomodoroTimer } from './pages/tools/PomodoroTimer';
import { ProjectEstimation } from './pages/tools/ProjectEstimation.tsx';
import { DecisionMatrix } from './pages/tools/DecisionMatrix';
import { Notes } from './pages/tools/Notes';
import { SupabaseProvider } from './lib/supabase/supabase-context';
import Kanban from './pages/tools/Kanban';
import { Settings } from './pages/settings/Settings';
import { ThemeProvider } from './store/themeStore';
import PMTable from './pages/tools/PMTable';
import { NewProject } from './pages/projects/NewProject';
import { Projects } from './pages/projects/Projects';

function App(): JSX.Element {
  return (
    <SupabaseProvider>
      <Router>
        <AuthProvider>
          <ThemeProvider>
            <Routes>
              <Route path="/" element={<Layout />}>
                {/* Public Routes */}
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<Signup />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="update-password" element={<UpdatePassword />} />
                <Route path="tools" element={<Tools />} />

                {/* Protected Routes */}
                <Route path="dashboard" element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                } />
                <Route path="settings" element={
                  <ProtectedRoute>
                    <Settings />
                  </ProtectedRoute>
                } />
                <Route path="tools/calculator" element={
                  <ProtectedRoute>
                    <Calculator />
                  </ProtectedRoute>
                } />
                <Route path="tools/pomodoro" element={
                  <ProtectedRoute>
                    <PomodoroTimer />
                  </ProtectedRoute>
                } />
                <Route path="tools/estimation" element={
                  <ProtectedRoute>
                    <ProjectEstimation />
                  </ProtectedRoute>
                } />
                <Route path="tools/decision-matrix" element={
                  <ProtectedRoute>
                    <DecisionMatrix />
                  </ProtectedRoute>
                } />
                <Route path="tools/notes" element={
                  <ProtectedRoute>
                    <Notes />
                  </ProtectedRoute>
                } />
                <Route path="tools/kanban" element={
                  <ProtectedRoute>
                    <Kanban />
                  </ProtectedRoute>
                } />
                <Route path="tools/pm-table" element={
                  <ProtectedRoute>
                    <PMTable />
                  </ProtectedRoute>
                } />
                <Route path="projects" element={
                  <ProtectedRoute>
                    <Projects />
                  </ProtectedRoute>
                } />
                <Route path="projects/new" element={
                  <ProtectedRoute>
                    <NewProject />
                  </ProtectedRoute>
                } />
                <Route path="projects/:projectId/table" element={
                  <ProtectedRoute>
                    <PMTable />
                  </ProtectedRoute>
                } />
                <Route path="projects/:projectId/kanban" element={
                  <ProtectedRoute>
                    <Kanban />
                  </ProtectedRoute>
                } />
              </Route>
            </Routes>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </SupabaseProvider>
  );
}

export default App;