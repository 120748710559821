import { useNavigate } from 'react-router-dom';
import { useSupabase } from '../lib/supabase/supabase-context';

export function Home() {
  const navigate = useNavigate();
  const { supabase } = useSupabase();

  const handleStartProject = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      // Store return path
      sessionStorage.setItem('returnTo', '/projects/new');
      navigate('/signup');
      return;
    }
    navigate('/projects/new');
  };

  return (
    <div className="bg-white dark:bg-gray-900">
      {/* Hero Section */}
      <div className="relative overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 lg:mt-16 lg:px-8 xl:mt-28">
              <div className="text-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white sm:text-5xl md:text-6xl">
                  <span className="block">Work smarter</span>
                  <span className="block text-indigo-600 dark:text-indigo-400">not harder.</span>
                </h1>
                <p className="mt-3 text-base text-gray-500 dark:text-gray-400 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl">
                  Stay on top of your work with an intuitive, flexible platform built to fit your flow.
                  From kanban to tables and beyond—it's all here, ready for you.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center">
                  <button
                    onClick={handleStartProject}
                    className="rounded-md bg-indigo-600 px-8 py-3 text-lg font-semibold text-white shadow-sm 
                             hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 
                             focus-visible:outline-offset-2 focus-visible:outline-indigo-600
                             dark:bg-indigo-500 dark:hover:bg-indigo-400
                             transition-all duration-200 ease-in-out"
                  >
                    Start a Project
                  </button>
                </div>
              </div>
            </main>
          </div>
        </div>
      </div>
    </div>
  );
}