import { useState, useMemo } from 'react';
import { 
  Calculator, 
  Clock, 
  LineChart, 
  FileText, 
  Kanban, 
  Target, 
  Repeat, 
  Workflow, 
  Scale, 
  Table 
} from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useToolStore } from '../store/toolStore';
import { SearchBar } from '../components/tools/SearchBar';
import { CategoryFilter } from '../components/tools/CategoryFilter';
import type { Tool } from '../types/tool';
import { useSupabase } from '../lib/supabase/supabase-context';

export function Tools() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const navigate = useNavigate();
  const { supabase } = useSupabase();
  const addUsage = useToolStore((state) => state.addUsage);

  const tools: Tool[] = [
    {
      id: 'pm-table',
      name: 'Project Table',
      description: 'Flexible project management with table and kanban views',
      icon: Table,
      path: '/tools/pm-table',
      category: 'Project Planning'
    },
    {
      id: 'kanban',
      name: 'Kanban Board',
      description: 'Visual project management with drag-and-drop',
      icon: Kanban,
      category: 'Project Planning'
    },
    {
      id: 'calculator',
      name: 'Calculator',
      description: 'Professional calculator with advanced features',
      icon: Calculator,
      path: '/tools/calculator',
      category: 'Calculation'
    },
    {
      id: 'pomodoro-timer',
      name: 'Pomodoro Timer',
      description: 'Focus and time management with Pomodoro technique',
      icon: Clock,
      path: '/tools/pomodoro',
      category: 'Time Management'
    },
    {
      id: 'estimation',
      name: 'Project Estimation',
      description: 'Estimate project costs and timelines',
      icon: LineChart,
      path: '/tools/estimation',
      category: 'Project Planning'
    },
    {
      id: 'decision-matrix',
      name: 'Decision Matrix',
      description: 'Compare options and make data-driven decisions with weighted criteria analysis',
      icon: Scale,
      path: '/tools/decision-matrix',
      category: 'Analysis'
    },
    {
      id: 'notes',
      name: 'Notes',
      description: 'Organize your thoughts and ideas with simple note-taking.',
      icon: FileText,
      path: '/tools/notes',
      category: 'Productivity'
    },
    {
      id: 'goal-tracker',
      name: 'Goal Tracker',
      description: 'Set and track personal and team goals',
      icon: Target,
      path: '/tools/goals',
      category: 'Productivity'
    },
    {
      id: 'habit-tracker',
      name: 'Habit Tracker',
      description: 'Build and maintain productive habits',
      icon: Repeat,
      path: '/tools/habits',
      category: 'Productivity'
    },
    {
      id: 'workflow-builder',
      name: 'Workflow Builder',
      description: 'Create and automate work processes',
      icon: Workflow,
      path: '/tools/workflow',
      category: 'Automation'
    }
  ];

  const filteredTools = useMemo(() => {
    return tools.filter((tool) => {
      const matchesSearch = 
        tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tool.description.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matchesCategory = 
        !selectedCategory || tool.category === selectedCategory;

      return matchesSearch && matchesCategory;
    });
  }, [tools, searchQuery, selectedCategory]);

  const handleToolClick = async (tool: Tool, e: React.MouseEvent) => {
    e.preventDefault();
    addUsage(tool.id);

    if (tool.id === 'kanban') {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        sessionStorage.setItem('returnTo', '/projects/new');
        navigate('/signup');
        return;
      }

      try {
        // Create project
        const { data: project, error: projectError } = await supabase
          .from('projects')
          .insert({
            title: 'New Project',
            user_id: user.id
          })
          .select()
          .single();

        if (projectError) throw projectError;
        if (!project) throw new Error('Failed to create project');

        // Create kanban board
        const { data: board, error: boardError } = await supabase
          .from('kanban_boards')
          .insert({
            user_id: user.id,
            project_id: project.id,
            title: project.title
          })
          .select()
          .single();

        if (boardError) throw boardError;
        if (!board) throw new Error('Failed to create board');

        // Create default columns
        const defaultColumns = [
          { title: 'To Do', position: 0 },
          { title: 'In Progress', position: 1 },
          { title: 'Done', position: 2 }
        ];

        const { error: columnsError } = await supabase
          .from('kanban_columns')
          .insert(
            defaultColumns.map(col => ({
              board_id: board.id,
              title: col.title,
              position: col.position
            }))
          );

        if (columnsError) throw columnsError;

        // Create kanban view
        const { error: viewError } = await supabase
          .from('project_views')
          .insert({
            project_id: project.id,
            user_id: user.id,
            type: 'kanban',
            title: 'Default View'
          });

        if (viewError) throw viewError;

        // Navigate to the new project's kanban view
        navigate(`/projects/${project.id}/kanban`);
      } catch (error) {
        console.error('Error creating project:', error);
      }
      return;
    } else if (tool.id === 'table') {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        sessionStorage.setItem('returnTo', '/projects/new');
        navigate('/signup');
        return;
      }

      try {
        // Create project
        const { data: project, error: projectError } = await supabase
          .from('projects')
          .insert({
            title: 'New Project',
            user_id: user.id
          })
          .select()
          .single();

        if (projectError) throw projectError;
        if (!project) throw new Error('Failed to create project');

        // Create table view
        const { error: viewError } = await supabase
          .from('project_views')
          .insert({
            project_id: project.id,
            user_id: user.id,
            type: 'table',
            title: 'Default View'
          });

        if (viewError) throw viewError;

        // Navigate to the new project's table view
        navigate(`/projects/${project.id}/table`);
      } catch (error) {
        console.error('Error creating project:', error);
      }
      return;
    }

    navigate(tool.path);
  };

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <h1 className="text-3xl font-extrabold text-gray-900 dark:text-white sm:text-4xl">
          Productivity Tools
        </h1>
        <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 dark:text-gray-400 sm:mt-4">
          Choose from our collection of professional tools
        </p>
      </div>

      <div className="mt-8 space-y-4">
        <SearchBar 
          searchQuery={searchQuery} 
          onSearchChange={setSearchQuery} 
        />
        <CategoryFilter 
          selectedCategory={selectedCategory} 
          onCategoryChange={setSelectedCategory} 
        />
      </div>

      <div className="mt-8 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {filteredTools.map((tool) => {
          const Icon = tool.icon;
          return (
            <Link
              key={tool.id}
              to={tool.path}
              onClick={(e) => handleToolClick(tool, e)}
              className="relative group bg-white dark:bg-gray-800 p-6 focus-within:ring-2 
                         focus-within:ring-inset focus-within:ring-indigo-500 rounded-lg 
                         shadow-sm hover:shadow-md transition-shadow flex flex-col"
            >
              <div>
                <span className="rounded-lg inline-flex p-3 bg-indigo-50 dark:bg-indigo-900 
                                 text-indigo-700 dark:text-indigo-300 ring-4 ring-white dark:ring-gray-800">
                  <Icon className="h-6 w-6" aria-hidden="true" />
                </span>
              </div>
              <div className="mt-8 flex-grow flex flex-col">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                  <span className="absolute inset-0" aria-hidden="true" />
                  {tool.name}
                </h3>
                <p className="mt-2 text-sm text-gray-500 dark:text-gray-400 flex-grow line-clamp-2">
                  {tool.description}
                </p>
                <div className="mt-4">
                  <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
                                  bg-indigo-100 dark:bg-indigo-900 text-indigo-800 dark:text-indigo-300">
                    {tool.category}
                  </span>
                </div>
              </div>
            </Link>
          );
        })}
      </div>

      {filteredTools.length === 0 && (
        <div className="text-center mt-12">
          <p className="text-gray-500 dark:text-gray-400">No tools found matching your criteria.</p>
        </div>
      )}
    </div>
  );
}