import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSupabase } from '../../lib/supabase/supabase-context';
import { Columns, KanbanSquare } from 'lucide-react';

export function NewProject() {
  const [searchParams] = useSearchParams();
  const defaultViewType = searchParams.get('view') as 'kanban' | 'table' | '';
  const [step, setStep] = useState(defaultViewType ? 2 : 1);
  const [projectData, setProjectData] = useState({
    name: '',
    viewType: defaultViewType || '' as 'kanban' | 'table'
  });
  const navigate = useNavigate();
  const { supabase } = useSupabase();

  const handleSubmit = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Create project
      const { data: project, error: projectError } = await supabase
        .from('projects')
        .insert({
          title: projectData.name,
          user_id: user.id
        })
        .select()
        .single();

      if (projectError) throw projectError;
      if (!project) throw new Error('Failed to create project');

      console.log('Project created:', project); // Debug log

      if (projectData.viewType === 'kanban') {
        // Create kanban board
        const { data: board, error: boardError } = await supabase
          .from('kanban_boards')
          .insert({
            user_id: user.id,
            project_id: project.id,
            title: project.title
          })
          .select()
          .single();

        if (boardError) throw boardError;
        if (!board) throw new Error('Failed to create board');
      }

      // Create default view
      const { error: viewError } = await supabase
        .from('project_views')
        .insert({
          project_id: project.id,
          user_id: user.id,
          type: projectData.viewType,
          title: 'Default View'
        });

      if (viewError) throw viewError;

      console.log('Navigating to:', `/projects/${project.id}/${projectData.viewType}`); // Debug log
      navigate(`/projects/${project.id}/${projectData.viewType}`);

    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  return (
    <div className="max-w-2xl mx-auto mt-16 p-6">
      {/* Step indicator */}
      <div className="mb-8">
        <div className="flex justify-center">
          {[1, 2].map((num) => (
            <div
              key={num}
              className={`w-3 h-3 rounded-full mx-2 ${
                step >= num ? 'bg-indigo-600' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>

      {step === 1 ? (
        // Step 1: Project Name
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-white">
            Name your project
          </h2>
          <input
            type="text"
            value={projectData.name}
            onChange={(e) => setProjectData({ ...projectData, name: e.target.value })}
            placeholder="Enter project name"
            className="w-full p-3 border rounded-md bg-white dark:bg-gray-800 
                     text-gray-900 dark:text-white
                     border-gray-300 dark:border-gray-600
                     focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400
                     focus:border-indigo-500 dark:focus:border-indigo-400
                     placeholder-gray-400 dark:placeholder-gray-500"
          />
          <button
            onClick={() => setStep(2)}
            disabled={!projectData.name}
            className="w-full bg-indigo-600 text-white p-3 rounded-md
                     hover:bg-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed
                     dark:bg-indigo-500 dark:hover:bg-indigo-400"
          >
            Continue
          </button>
        </div>
      ) : (
        // Step 2: View Selection
        <div className="space-y-6">
          <h2 className="text-2xl font-bold text-center text-gray-900 dark:text-white">
            Choose your view
          </h2>
          <div className="grid grid-cols-2 gap-4">
            {['table', 'kanban'].map((type) => (
              <button
                key={type}
                onClick={() => setProjectData({ ...projectData, viewType: type as 'kanban' | 'table' })}
                className={`p-6 border rounded-lg flex flex-col items-center justify-center
                          ${projectData.viewType === type 
                            ? 'border-indigo-600 dark:border-indigo-400 bg-indigo-50 dark:bg-indigo-900/20' 
                            : 'border-gray-200 dark:border-gray-700 hover:border-indigo-300 dark:hover:border-indigo-600'}
                          text-gray-900 dark:text-white`}
              >
                {type === 'kanban' ? (
                  <KanbanSquare className="w-8 h-8 mb-2" />
                ) : (
                  <Columns className="w-8 h-8 mb-2" />
                )}
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </button>
            ))}
          </div>
          <button
            onClick={handleSubmit}
            disabled={!projectData.viewType}
            className="w-full bg-indigo-600 text-white p-3 rounded-md
                     hover:bg-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed
                     dark:bg-indigo-500 dark:hover:bg-indigo-400"
          >
            Create Project
          </button>
        </div>
      )}
    </div>
  );
} 