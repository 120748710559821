import { Outlet, useLocation } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { Sidebar } from './Sidebar';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import type { Project } from '../../types/project';
import { useSupabase } from '../../lib/supabase/supabase-context';

export function Layout() {
  const location = useLocation();
  const { projectId } = useParams();
  const { supabase } = useSupabase();
  const isProjectView = location.pathname.startsWith('/projects/') || 
                       location.pathname === '/tools/kanban' || 
                       location.pathname === '/tools/pm-table';
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data } = await supabase
          .from('projects')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });
        
        if (data) setProjects(data);
      } catch (error) {
        console.error('Error loading projects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadProjects();
  }, []);

  if (isLoading && isProjectView) {
    return (
      <div className="min-h-screen bg-white dark:bg-gray-900">
        <Header />
        <main className="pt-16 flex items-center justify-center h-[calc(100vh-4rem)]">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </main>
      </div>
    );
  }

  const content = (
    <div className={`flex-1 transition-all duration-300 ${isProjectView && isSidebarExpanded ? 'ml-64' : 'ml-0'}`}>
      <Outlet context={{ projects, setProjects, isSidebarExpanded }} />
    </div>
  );

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900">
      <Header />
      <main className="pt-16">
        {isProjectView ? (
          <div className="flex h-[calc(100vh-4rem)]">
            <Sidebar 
              isExpanded={isSidebarExpanded}
              onToggle={() => setIsSidebarExpanded(!isSidebarExpanded)}
              selectedProject={projectId}
              onSelectProject={() => {}}
              projects={projects}
              setProjects={setProjects}
            />
            {content}
          </div>
        ) : (
          content
        )}
      </main>
      {!isProjectView && <Footer />}
    </div>
  );
}