import { useState, useEffect } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSupabase } from '../../lib/supabase/supabase-context';
import type { Project } from '../../types/project';

interface LayoutContext {
  projects: Project[];
  setProjects: (projects: Project[]) => void;
  isSidebarExpanded: boolean;
}

export function Projects() {
  const navigate = useNavigate();
  const { supabase } = useSupabase();
  const context = useOutletContext<LayoutContext>();
  const { projects, setProjects } = context;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadProjects = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data: projects } = await supabase
          .from('projects')
          .select('*')
          .eq('user_id', user.id)
          .order('created_at', { ascending: false });

        if (projects) {
          setProjects(projects);
          // If there's at least one project, navigate to the last accessed one
          if (projects.length > 0) {
            const lastProject = projects[0];
            // Get the last used view type for this project
            const { data: view } = await supabase
              .from('project_views')
              .select('type')
              .eq('project_id', lastProject.id)
              .single();

            if (view) {
              navigate(`/projects/${lastProject.id}/${view.type}`);
            } else {
              // Fallback to table view if no view exists
              navigate(`/projects/${lastProject.id}/table`);
            }
          }
        }
      } catch (error) {
        console.error('Error loading projects:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (projects.length === 0) {
      loadProjects();
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (projects.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
          No projects yet
        </h1>
        <p className="text-gray-600 dark:text-gray-400 mb-8 text-center">
          Create your first project to get started
        </p>
        <button
          onClick={() => navigate('/projects/new')}
          className="bg-indigo-600 text-white px-6 py-2 rounded-md
                   hover:bg-indigo-500 transition-colors
                   dark:bg-indigo-500 dark:hover:bg-indigo-400"
        >
          Create Project
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Projects
        </h1>
        <button
          onClick={() => navigate('/projects/new')}
          className="bg-indigo-600 text-white px-4 py-2 rounded-md
                   hover:bg-indigo-500 transition-colors
                   dark:bg-indigo-500 dark:hover:bg-indigo-400"
        >
          New Project
        </button>
      </div>

      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {projects.map((project) => (
          <div
            key={project.id}
            className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6
                     border border-gray-200 dark:border-gray-700
                     hover:border-indigo-500 dark:hover:border-indigo-400
                     cursor-pointer transition-all"
            onClick={() => navigate(`/projects/${project.id}/table`)}
          >
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
              {project.title}
            </h2>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Created {new Date(project.created_at).toLocaleDateString()}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
} 