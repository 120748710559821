import { useState, useEffect, useCallback } from 'react';
import { Plus } from 'lucide-react';
import type { Table, TableColumn, ColumnType } from '../../types/table';
import type { Project } from '../../types/project';
import { TableView } from '../../components/table/TableView';
import { useSupabase } from '../../lib/supabase/supabase-context';
import { ErrorBoundary } from 'react-error-boundary';
import { Sidebar } from '../../components/layout/Sidebar';
import { debounce } from '../../lib/utils';
import { useParams, useOutletContext } from 'react-router-dom';
import { ColumnTypeSelector } from '../../components/table/ColumnTypeSelector';
import { COLUMN_TYPES } from '../../lib/constants/columnTypes';

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="p-4 text-red-500">
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
    </div>
  );
}

interface LayoutContext {
  projects: Project[];
  setProjects: (projects: Project[]) => void;
  isSidebarExpanded: boolean;
}

export default function PMTable() {
  const { projectId } = useParams();
  const { supabase } = useSupabase();
  const { projects, setProjects, isSidebarExpanded } = useOutletContext<LayoutContext>();
  const [board, setBoard] = useState<Table>({
    id: '',
    title: '',
    columns: []
  });
  const [isLoading, setIsLoading] = useState(true);
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'error' | null>(null);
  const [showColumnSelector, setShowColumnSelector] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (!projectId) return;
      setIsLoading(true);
      
      try {
        // Load project details first
        const { data: project } = await supabase
          .from('projects')
          .select('*')
          .eq('id', projectId)
          .single();

        if (!project) throw new Error('Project not found');

        // Load all projects for sidebar if needed
        if (projects.length === 0) {
          const { data: allProjects } = await supabase
            .from('projects')
            .select('*')
            .order('created_at', { ascending: false });

          if (allProjects) {
            setProjects(allProjects);
          }
        }

        // Get or create a view for this project
        const { data: existingView } = await supabase
          .from('project_views')
          .select('*')
          .eq('project_id', projectId)
          .eq('type', 'table')
          .single();

        let viewId;
        if (existingView) {
          viewId = existingView.id;
        } else {
          const { data: newView } = await supabase
            .from('project_views')
            .insert([
              {
                project_id: projectId,
                type: 'table',
                title: 'Default View'
              }
            ])
            .select()
            .single();
          
          if (!newView) throw new Error('Failed to create view');
          viewId = newView.id;

          // Create default Title column for new views
          const { data: titleColumn } = await supabase
            .from('view_columns')
            .insert([
              {
                title: 'Title',
                view_id: viewId,
                position: 0,
                type: 'text'
              }
            ])
            .select()
            .single();
        }

        // Load columns for this view
        const { data: columns } = await supabase
          .from('view_columns')
          .select('*')
          .eq('view_id', viewId)
          .order('position');

        const tableColumns: TableColumn[] = (columns || []).map(col => ({
          id: col.id,
          title: col.title,
          type: col.type || 'text',
          position: col.position,
          settings: col.settings
        }));

        // Load tasks for this view
        const { data: tasks } = await supabase
          .from('view_items')
          .select('*')
          .eq('view_id', viewId)
          .order('position');

        setBoard({
          id: viewId,
          title: project.title,
          columns: tableColumns,
          tasks: tasks || []
        });
      } catch (error) {
        console.error('Error loading project data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [projectId]);

  const addColumn = async (type: ColumnType) => {
    try {
      if (!board.id) return;

      setShowColumnSelector(false);

      const columnType = COLUMN_TYPES.find((t: { type: ColumnType }) => t.type === type);
      const { data: newColumn, error } = await supabase
        .from('view_columns')
        .insert([
          {
            title: columnType?.label || 'New Column',
            view_id: board.id,
            position: board.columns.length,
            type: type,
            settings: {
              options: columnType?.defaultOptions || []
            }
          }
        ])
        .select()
        .single();

      if (error) throw error;

      if (newColumn) {
        const tableColumn: TableColumn = {
          id: newColumn.id,
          title: newColumn.title,
          type: newColumn.type,
          position: newColumn.position,
          settings: newColumn.settings
        };

        setBoard(prev => ({
          ...prev,
          columns: [...prev.columns, tableColumn]
        }));
      }
    } catch (error) {
      console.error('Failed to add column:', error);
    }
  };

  const debouncedSaveTitle = useCallback(
    debounce(async (projectId: string, newTitle: string) => {
      try {
        const { error } = await supabase
          .from('projects')
          .update({ title: newTitle })
          .eq('id', projectId);
        
        if (error) throw error;
        setSaveStatus('saved');
      } catch (error) {
        console.error('Failed to update project title:', error);
        setSaveStatus('error');
      }
    }, 750),
    [supabase]
  );

  const updateProjectTitle = (newTitle: string) => {
    if (!board.id) return;

    setSaveStatus('saving');
    setBoard(prev => ({ ...prev, title: newTitle }));
    
    setProjects(prev => prev.map(project => 
      project.id === board.id 
        ? { ...project, title: newTitle }
        : project
    ));

    debouncedSaveTitle(board.id, newTitle);
  };

  // Update project selection handler to persist selection
  const handleProjectSelect = async (projectId: string) => {
    await loadBoard(projectId);
  };

  // Add save status cleanup
  useEffect(() => {
    if (saveStatus === 'saved') {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const loadBoard = async (projectId: string) => {
    try {
      // Load columns for this project
      const { data: columns } = await supabase
        .from('view_columns')
        .select('*')
        .eq('view_id', projectId)
        .order('position');

      if (columns) {
        setBoard(prev => ({
          ...prev,
          id: projectId,
          columns: columns.map(column => ({
            id: column.id,
            title: column.title,
            position: column.position,
            type: column.type || 'text' as ColumnType,
            settings: column.settings
          }))
        }));
      }
    } catch (error) {
      console.error('Error loading board:', error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex-1 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (board.columns.length === 0) {
    return (
      <div className="flex-1">
        <div className="px-4 sm:px-6 lg:px-8 py-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex justify-between items-center">
            <input
              type="text"
              value={board.title}
              onChange={(e) => updateProjectTitle(e.target.value)}
              className={`text-2xl font-bold bg-transparent border-none outline-none 
                         focus:outline-none focus:ring-0 hover:bg-gray-50 dark:hover:bg-gray-800/50
                         focus:bg-gray-50 dark:focus:bg-gray-800/50 rounded px-2 -ml-2
                         transition-colors duration-150 ease-in-out
                         text-gray-900 dark:text-white placeholder-gray-400 
                         dark:placeholder-gray-500`}
            />
            {saveStatus && (
              <div className="flex items-center gap-2">
                <div className={`flex items-center gap-1.5 text-xs font-medium transition-all duration-300
                  ${saveStatus === 'saved' ? 'animate-fade-out' : 'opacity-100'}`}
                >
                  {saveStatus === 'saving' ? (
                    <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                      <div className="w-1.5 h-1.5 rounded-full bg-gray-400 dark:bg-gray-500 animate-pulse" />
                      Saving
                    </div>
                  ) : saveStatus === 'saved' ? (
                    <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                      <div className="w-1.5 h-1.5 rounded-full bg-emerald-400 dark:bg-emerald-500" />
                      Saved
                    </div>
                  ) : saveStatus === 'error' ? (
                    <div className="flex items-center gap-1.5 text-red-500 dark:text-red-400">
                      <div className="w-1.5 h-1.5 rounded-full bg-red-500 dark:bg-red-400" />
                      Failed to save
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="flex gap-2">
              <button
                onClick={() => setShowColumnSelector(true)}
                className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
              >
                <Plus className="h-4 w-4" />
                Add Column
              </button>
            </div>
          </div>
        </div>
        <div className="p-6">
          <TableView 
            board={board} 
            setBoard={setBoard}
          />
        </div>
      </div>
    );
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="flex-1">
        <div className="px-4 sm:px-6 lg:px-8 py-4 border-b border-gray-200 dark:border-gray-700">
          <div className="flex justify-between items-center">
            <input
              type="text"
              value={board.title}
              onChange={(e) => updateProjectTitle(e.target.value)}
              className={`text-2xl font-bold bg-transparent border-none outline-none 
                         focus:outline-none focus:ring-0 hover:bg-gray-50 dark:hover:bg-gray-800/50
                         focus:bg-gray-50 dark:focus:bg-gray-800/50 rounded px-2 -ml-2
                         transition-colors duration-150 ease-in-out
                         text-gray-900 dark:text-white placeholder-gray-400 
                         dark:placeholder-gray-500`}
            />
            {saveStatus && (
              <div className="flex items-center gap-2">
                <div className={`flex items-center gap-1.5 text-xs font-medium transition-all duration-300
                  ${saveStatus === 'saved' ? 'animate-fade-out' : 'opacity-100'}`}
                >
                  {saveStatus === 'saving' ? (
                    <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                      <div className="w-1.5 h-1.5 rounded-full bg-gray-400 dark:bg-gray-500 animate-pulse" />
                      Saving
                    </div>
                  ) : saveStatus === 'saved' ? (
                    <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                      <div className="w-1.5 h-1.5 rounded-full bg-emerald-400 dark:bg-emerald-500" />
                      Saved
                    </div>
                  ) : saveStatus === 'error' ? (
                    <div className="flex items-center gap-1.5 text-red-500 dark:text-red-400">
                      <div className="w-1.5 h-1.5 rounded-full bg-red-500 dark:bg-red-400" />
                      Failed to save
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="flex gap-2">
              <button
                onClick={() => setShowColumnSelector(true)}
                className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
              >
                <Plus className="h-4 w-4" />
                Add Column
              </button>
            </div>
          </div>
        </div>
        <div className="p-6">
          <TableView 
            board={board} 
            setBoard={setBoard}
          />
        </div>
      </div>
      {showColumnSelector && (
        <ColumnTypeSelector
          onSelect={addColumn}
          onClose={() => setShowColumnSelector(false)}
        />
      )}
    </ErrorBoundary>
  );
} 