import { ColumnType } from '../../types/table';
import { Calendar, ListTodo, ArrowUpDown, Type, Ruler } from 'lucide-react';

interface ColumnTypeSelectorProps {
  initialType?: ColumnType;
  onSelect: (type: ColumnType) => void;
  onClose: () => void;
}

const COLUMN_TYPES = [
  {
    type: 'status' as ColumnType,
    label: 'Status',
    description: 'Track progress with customizable status labels',
    icon: ListTodo,
    defaultOptions: ['Not Started', 'In Progress', 'Done']
  },
  {
    type: 'date' as ColumnType,
    label: 'Due Date',
    description: 'Add dates and deadlines',
    icon: Calendar
  },
  {
    type: 'priority' as ColumnType,
    label: 'Priority',
    description: 'Set priority levels',
    icon: ArrowUpDown,
    defaultOptions: ['High', 'Medium', 'Low']
  },
  {
    type: 'text' as ColumnType,
    label: 'Text',
    description: 'Add any text information',
    icon: Type
  },
  {
    type: 'size' as ColumnType,
    label: 'Size',
    description: 'T-shirt sizes or story points',
    icon: Ruler,
    defaultOptions: ['XS', 'S', 'M', 'L', 'XL']
  }
];

export function ColumnTypeSelector({ initialType, onSelect, onClose }: ColumnTypeSelectorProps) {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
          {initialType ? 'Change Column Type' : 'Choose Column Type'}
        </h2>
        <div className="space-y-2">
          {COLUMN_TYPES.map(({ type, label, description, icon: Icon }) => (
            <button
              key={type}
              onClick={() => onSelect(type)}
              className={`w-full p-4 text-left flex items-start gap-4 rounded-lg
                       hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors
                       ${initialType === type ? 'bg-indigo-50 dark:bg-indigo-900/20' : ''}`}
            >
              <Icon className="w-5 h-5 text-gray-500 dark:text-gray-400 mt-1" />
              <div>
                <div className="font-medium text-gray-900 dark:text-white">
                  {label}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                  {description}
                </div>
              </div>
            </button>
          ))}
        </div>
        <button
          onClick={onClose}
          className="mt-4 w-full px-4 py-2 text-sm text-gray-600 dark:text-gray-400
                   hover:text-gray-800 dark:hover:text-gray-200"
        >
          Cancel
        </button>
      </div>
    </div>
  );
} 