import type { CategoryInfo } from '../types/tool';

export const categories: CategoryInfo[] = [
  {
    name: 'Calculation',
    description: 'Tools for numerical analysis and computations',
    color: 'blue'
  },
  {
    name: 'Time Management',
    description: 'Track and optimize your time usage',
    color: 'green'
  },
  {
    name: 'Project Planning',
    description: 'Plan and organize projects effectively',
    color: 'purple'
  },
  {
    name: 'Documentation',
    description: 'Create and manage documentation',
    color: 'yellow'
  },
  {
    name: 'Productivity',
    description: 'Boost your personal productivity',
    color: 'red'
  },
  {
    name: 'Automation',
    description: 'Automate repetitive tasks',
    color: 'indigo'
  }
];