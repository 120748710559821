import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../lib/supabase/AuthProvider';

interface ProtectedRouteProps {
  children: ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { user } = useAuth();

  if (!user) {
    sessionStorage.setItem('returnTo', window.location.pathname);
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
} 