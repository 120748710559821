import { useState } from 'react';
import type { TableColumn } from '../../../types/table';
import { Calendar } from 'lucide-react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface CellProps {
  column: TableColumn;
  value: any;
  onChange: (value: any) => void;
}

// Text Cell
export function TextCell({ value, onChange }: CellProps) {
  const [editing, setEditing] = useState(false);

  if (!editing) {
    return (
      <div 
        className="px-2 py-1 cursor-text text-gray-900 dark:text-gray-100"
        onClick={() => setEditing(true)}
      >
        {value || ''}
      </div>
    );
  }

  return (
    <input
      type="text"
      value={value || ''}
      onChange={(e) => onChange(e.target.value)}
      onBlur={() => setEditing(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setEditing(false);
        }
      }}
      className="w-full px-2 py-1 bg-transparent border border-gray-300 dark:border-gray-600 
                 rounded focus:ring-2 focus:ring-indigo-500 dark:focus:ring-indigo-400 
                 focus:border-transparent text-gray-900 dark:text-gray-100"
      autoFocus
    />
  );
}

// Status Cell
export function StatusCell({ column, value, onChange }: CellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const options = column.settings?.options || ['Not Started', 'In Progress', 'Done'];

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Done':
        return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-100';
      case 'In Progress':
        return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100';
      default:
        return 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100';
    }
  };

  return (
    <div className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer px-3 py-1 rounded-full text-sm font-medium inline-flex 
                   ${value ? getStatusColor(value) : 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100'}`}
      >
        {value || 'Select status'}
      </div>
      
      {isOpen && (
        <div className="absolute z-50 mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu">
            {options.map((option) => (
              <div
                key={option}
                className={`px-4 py-2 text-sm cursor-pointer ${getStatusColor(option)} 
                           hover:bg-gray-100 dark:hover:bg-gray-700`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
                role="menuitem"
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Date Cell
export function DateCell({ value, onChange }: CellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const date = value ? new Date(value) : null;

  return (
    <div className="relative">
      <div 
        onClick={() => setIsOpen(true)}
        className="px-2 py-1 cursor-pointer text-gray-900 dark:text-gray-100 flex items-center gap-2 hover:bg-gray-50 dark:hover:bg-gray-800/50 rounded"
      >
        <Calendar className="h-4 w-4 text-gray-400" />
        {date ? date.toLocaleDateString(undefined, {
          year: 'numeric',
          month: 'short',
          day: 'numeric'
        }) : 'Set date'}
      </div>

      {isOpen && (
        <div className="absolute z-50 mt-1">
          <DatePicker
            selected={date}
            onChange={(date) => {
              onChange(date?.toISOString());
              setIsOpen(false);
            }}
            onClickOutside={() => setIsOpen(false)}
            inline
            calendarClassName="dark:bg-gray-800 dark:text-white border-gray-200 dark:border-gray-700 shadow-lg rounded-lg"
            dayClassName={(d) => 
              `dark:hover:bg-gray-700 hover:bg-gray-100 rounded ${
                d.toDateString() === date?.toDateString() 
                  ? 'bg-indigo-500 text-white hover:bg-indigo-600 dark:hover:bg-indigo-600' 
                  : ''
              }`
            }
            monthClassName={() => "dark:text-gray-100"}
            weekDayClassName={() => "dark:text-gray-400"}
            todayButton="Today"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperClassName="react-datepicker-popper"
            wrapperClassName="react-datepicker-wrapper"
          />
        </div>
      )}
    </div>
  );
}

// Priority Cell
export function PriorityCell({ value, onChange }: CellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const options = ['High', 'Medium', 'Low'];

  const getPriorityColor = (priority: string) => {
    switch (priority) {
      case 'High':
        return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-100';
      case 'Medium':
        return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-100';
      case 'Low':
        return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-100';
      default:
        return 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100';
    }
  };

  return (
    <div className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer px-3 py-1 rounded-full text-sm font-medium inline-flex 
                   ${value ? getPriorityColor(value) : 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100'}`}
      >
        {value || 'Set priority'}
      </div>
      
      {isOpen && (
        <div className="absolute z-50 mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu">
            {options.map((option) => (
              <div
                key={option}
                className={`px-4 py-2 text-sm cursor-pointer ${getPriorityColor(option)} 
                           hover:bg-gray-100 dark:hover:bg-gray-700`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
                role="menuitem"
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Size Cell
export function SizeCell({ value, onChange }: CellProps) {
  const [isOpen, setIsOpen] = useState(false);
  const options = ['XS', 'S', 'M', 'L', 'XL'];

  const getSizeColor = (size: string) => {
    switch (size) {
      case 'XS':
        return 'bg-green-100 dark:bg-green-900 text-green-800 dark:text-green-100';
      case 'S':
        return 'bg-blue-100 dark:bg-blue-900 text-blue-800 dark:text-blue-100';
      case 'M':
        return 'bg-yellow-100 dark:bg-yellow-900 text-yellow-800 dark:text-yellow-100';
      case 'L':
        return 'bg-orange-100 dark:bg-orange-900 text-orange-800 dark:text-orange-100';
      case 'XL':
        return 'bg-red-100 dark:bg-red-900 text-red-800 dark:text-red-100';
      default:
        return 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100';
    }
  };

  return (
    <div className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer px-3 py-1 rounded-full text-sm font-medium inline-flex
                   ${value ? getSizeColor(value) : 'bg-gray-100 dark:bg-gray-800 text-gray-800 dark:text-gray-100'}`}
      >
        {value || 'Set size'}
      </div>
      
      {isOpen && (
        <div className="absolute z-50 mt-1 w-48 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5">
          <div className="py-1" role="menu">
            {options.map((option) => (
              <div
                key={option}
                className={`px-4 py-2 text-sm cursor-pointer ${getSizeColor(option)}
                         hover:bg-gray-100 dark:hover:bg-gray-700`}
                onClick={() => {
                  onChange(option);
                  setIsOpen(false);
                }}
                role="menuitem"
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

// Cell Factory
export function TableCell({ column, value, onChange }: CellProps) {
  switch (column.type) {
    case 'text':
      return <TextCell column={column} value={value} onChange={onChange} />;
    case 'status':
      return <StatusCell column={column} value={value} onChange={onChange} />;
    case 'date':
      return <DateCell column={column} value={value} onChange={onChange} />;
    case 'priority':
      return <PriorityCell column={column} value={value} onChange={onChange} />;
    case 'size':
      return <SizeCell column={column} value={value} onChange={onChange} />;
    default:
      return <TextCell column={column} value={value} onChange={onChange} />;
  }
} 