import { useState } from 'react';
import { ChevronLeft, ChevronRight, Plus, Trash2 } from 'lucide-react';
import { useSupabase } from '../../lib/supabase/supabase-context';
import type { Project } from '../../types/project';
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
  isExpanded: boolean;
  onToggle: () => void;
  selectedProject?: string;
  onSelectProject: (projectId: string) => void;
  projects: Project[];
  setProjects: React.Dispatch<React.SetStateAction<Project[]>>;
}

interface DeleteModalProps {
  isOpen: boolean;
  projectTitle: string;
  onConfirm: () => void;
  onCancel: () => void;
}

function DeleteModal({ isOpen, projectTitle, onConfirm, onCancel }: DeleteModalProps) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg max-w-md w-full mx-4">
        <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
          Delete Project?
        </h3>
        <p className="text-gray-600 dark:text-gray-400 mb-4">
          Are you sure you want to delete "{projectTitle}"? This action cannot be undone.
        </p>
        <div className="flex justify-end gap-3">
          <button
            onClick={onCancel}
            className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

export function Sidebar({ isExpanded, onToggle, selectedProject, onSelectProject, projects, setProjects }: SidebarProps) {
  const { supabase } = useSupabase();
  const [deleteModal, setDeleteModal] = useState<{ isOpen: boolean; projectId: string; title: string }>({
    isOpen: false,
    projectId: '',
    title: ''
  });
  const [showProjectTypeMenu, setShowProjectTypeMenu] = useState(false);
  const navigate = useNavigate();

  const createProject = async (type: 'table' | 'kanban') => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    try {
      // Create project
      const { data: project, error: projectError } = await supabase
        .from('projects')
        .insert([{
          title: 'New Project',
          user_id: user.id
        }])
        .select()
        .single();

      if (projectError) throw projectError;
      if (!project) throw new Error('Failed to create project');

      if (type === 'kanban') {
        // Create kanban board
        const { data: board, error: boardError } = await supabase
          .from('kanban_boards')
          .insert({
            user_id: user.id,
            project_id: project.id,
            title: project.title
          })
          .select()
          .single();

        if (boardError) throw boardError;
        if (!board) throw new Error('Failed to create board');

        // Create default columns
        const defaultColumns = [
          { title: 'To Do', position: 0 },
          { title: 'In Progress', position: 1 },
          { title: 'Done', position: 2 }
        ];

        const { error: columnsError } = await supabase
          .from('kanban_columns')
          .insert(
            defaultColumns.map(col => ({
              board_id: board.id,
              title: col.title,
              position: col.position
            }))
          );

        if (columnsError) throw columnsError;
      }

      // Create view
      const { error: viewError } = await supabase
        .from('project_views')
        .insert({
          project_id: project.id,
          user_id: user.id,
          type: type,
          title: 'Default View'
        });

      if (viewError) throw viewError;

      setProjects([project, ...projects]);
      setShowProjectTypeMenu(false);
      navigate(`/projects/${project.id}/${type}`);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleDelete = async () => {
    if (!deleteModal.projectId) return;
    
    try {
      const projectToDelete = deleteModal.projectId;
      const isCurrentProject = projectToDelete === selectedProject;

      // First, delete all tasks from kanban columns
      const { data: board } = await supabase
        .from('kanban_boards')
        .select('id')
        .eq('project_id', projectToDelete)
        .single();

      if (board) {
        const { data: columns } = await supabase
          .from('kanban_columns')
          .select('id')
          .eq('board_id', board.id);

        if (columns) {
          for (const column of columns) {
            await supabase
              .from('kanban_tasks')
              .delete()
              .eq('column_id', column.id);
          }
        }

        // Delete columns
        await supabase
          .from('kanban_columns')
          .delete()
          .eq('board_id', board.id);

        // Delete board
        await supabase
          .from('kanban_boards')
          .delete()
          .eq('id', board.id);
      }

      // Delete project views
      await supabase
        .from('project_views')
        .delete()
        .eq('project_id', projectToDelete);

      // Finally delete the project
      await supabase
        .from('projects')
        .delete()
        .eq('id', projectToDelete);

      // Update local state
      const updatedProjects = projects.filter(p => p.id !== projectToDelete);
      setProjects(updatedProjects);
      
      // If we deleted current project and there are others, select the first one
      if (isCurrentProject && updatedProjects.length > 0) {
        const nextProject = updatedProjects[0];
        onSelectProject(nextProject.id);
      }

      setDeleteModal({ isOpen: false, projectId: '', title: '' });
    } catch (error) {
      console.error('Error deleting project:', error);
    }
  };

  const handleProjectClick = async (projectId: string) => {
    try {
      // Get the last used view type for this project
      const { data: view } = await supabase
        .from('project_views')
        .select('type')
        .eq('project_id', projectId)
        .single();

      if (view) {
        navigate(`/projects/${projectId}/${view.type}`);
      } else {
        // Fallback to table view if no view exists
        navigate(`/projects/${projectId}/table`);
      }
    } catch (error) {
      console.error('Error getting project view:', error);
      // Fallback to table view on error
      navigate(`/projects/${projectId}/table`);
    }
  };

  return (
    <>
      <aside 
        className={`fixed left-0 top-16 h-[calc(100vh-4rem)] bg-gray-50 dark:bg-gray-800 
                    border-r dark:border-gray-700 transition-all duration-300 ease-in-out ${
          isExpanded ? 'w-64' : 'w-0'
        }`}
      >
        <button
          onClick={onToggle}
          className="absolute -right-4 top-4 bg-white dark:bg-gray-700 rounded-full p-1 
                    shadow-md hover:bg-gray-50 dark:hover:bg-gray-600 
                    border dark:border-gray-600"
          aria-label={isExpanded ? 'Collapse sidebar' : 'Expand sidebar'}
        >
          {isExpanded ? <ChevronLeft size={16} /> : <ChevronRight size={16} />}
        </button>
        
        {isExpanded && (
          <div className="p-4">
            <div className="flex justify-between items-center mb-4 relative">
              <h2 className="text-sm font-medium text-gray-900 dark:text-white">Projects</h2>
              <button
                onClick={() => setShowProjectTypeMenu(!showProjectTypeMenu)}
                className="p-1 text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
              >
                <Plus className="h-5 w-5" />
              </button>
              
              {showProjectTypeMenu && (
                <div className="absolute right-0 top-8 w-48 py-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg border dark:border-gray-700 z-50">
                  <button
                    onClick={() => createProject('table')}
                    className="w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    Table Project
                  </button>
                  <button
                    onClick={() => createProject('kanban')}
                    className="w-full px-4 py-2 text-left text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700"
                  >
                    Kanban Project
                  </button>
                </div>
              )}
            </div>
            
            <nav className="space-y-1">
              {projects.map(project => (
                <div
                  key={project.id}
                  className={`flex items-center justify-between group p-2 rounded-lg cursor-pointer mb-1
                            ${selectedProject === project.id 
                              ? 'bg-indigo-50 dark:bg-indigo-900/20 text-indigo-600 dark:text-indigo-400' 
                              : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700/50'}`}
                >
                  <div
                    className="flex-1 truncate"
                    onClick={(e) => {
                      e.preventDefault();
                      handleProjectClick(project.id);
                    }}
                  >
                    {project.title}
                  </div>
                  <button
                    onClick={() => setDeleteModal({ 
                      isOpen: true, 
                      projectId: project.id, 
                      title: project.title 
                    })}
                    className="opacity-0 group-hover:opacity-100 p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded"
                  >
                    <Trash2 className="w-4 h-4 text-gray-500 dark:text-gray-400" />
                  </button>
                </div>
              ))}
            </nav>
          </div>
        )}
      </aside>

      <DeleteModal
        isOpen={deleteModal.isOpen}
        projectTitle={deleteModal.title}
        onConfirm={handleDelete}
        onCancel={() => setDeleteModal({ isOpen: false, projectId: '', title: '' })}
      />
    </>
  );
} 