import { useState, useEffect, useCallback } from 'react';
import { Plus } from 'lucide-react';
import type { KanbanBoard } from '../../types/kanban';
import type { Project } from '../../types/project';
import { KanbanView } from '../../components/kanban/KanbanView';
import { useSupabase } from '../../lib/supabase/supabase-context';
import { ErrorBoundary } from 'react-error-boundary';
import { Sidebar } from '../../components/layout/Sidebar';
import { debounce } from '../../lib/utils';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="p-4 text-red-500">
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
    </div>
  );
}

interface LayoutContext {
  projects: Project[];
  setProjects: (projects: Project[]) => void;
  isSidebarExpanded: boolean;
}

export default function Kanban() {
  const { projectId } = useParams();
  const { supabase } = useSupabase();
  const { projects, setProjects, isSidebarExpanded } = useOutletContext<LayoutContext>();
  const [board, setBoard] = useState<KanbanBoard>({
    id: '',
    title: 'My Project',
    columns: []
  });
  const [saveStatus, setSaveStatus] = useState<'saved' | 'saving' | 'error' | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBoardLoading, setIsBoardLoading] = useState(false);
  const navigate = useNavigate();

  const loadProjects = async () => {
    try {
      const { data } = await supabase
        .from('projects')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (data) setProjects(data);
    } catch (error) {
      console.error('Error loading projects:', error);
    }
  };

  // Update the initialization effect to handle loading states better
  useEffect(() => {
    const initializeBoard = async () => {
      if (!projectId) return;
      
      setIsBoardLoading(true);
      try {
        const { error } = await supabase.auth.getUser();
        if (error) throw error;
        
        // First load projects if we don't have them
        if (projects.length === 0) {
          await loadProjects();
        }
        
        await loadBoard(projectId);
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setIsBoardLoading(false);
      }
    };

    initializeBoard();
  }, [projectId]);

  // Load board data
  const loadBoard = async (projectId?: string) => {
    if (!projectId) return;
    
    setBoard({ id: '', title: '', columns: [] }); // Clear board immediately
    
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Load project details first
      const { data: project } = await supabase
        .from('projects')
        .select('*')
        .eq('id', projectId)
        .single();

      if (!project) {
        localStorage.removeItem('lastSelectedProjectId');
        return;
      }

      setBoard(prev => ({ ...prev, title: project.title }));

      // Get or create default board for this project
      let { data: boards } = await supabase
        .from('kanban_boards')
        .select('*')
        .eq('user_id', user.id)
        .eq('project_id', projectId)
        .limit(1);

      let boardId;
      if (!boards?.length) {
        // Create default board
        const { data: newBoard } = await supabase
          .from('kanban_boards')
          .insert({ 
            user_id: user.id, 
            project_id: projectId,
            title: project?.title || 'New Project'
          })
          .select()
          .single();
        
        if (!newBoard) throw new Error('Failed to create board');
        boardId = newBoard.id;

        // Create default columns for new board
        const defaultColumns = [
          { title: 'To Do', position: 0 },
          { title: 'In Progress', position: 1 },
          { title: 'Done', position: 2 }
        ];

        await supabase
          .from('kanban_columns')
          .insert(
            defaultColumns.map(col => ({
              board_id: boardId,
              title: col.title,
              position: col.position
            }))
          );
      } else {
        boardId = boards[0].id;
      }

      // Set the board ID immediately and independently
      setBoard(prev => ({ ...prev, id: boardId, title: project?.title || prev.title }));

      // Load columns with tasks - use fresh data
      const { data: columns } = await supabase
        .from('kanban_columns')
        .select(`
          id,
          title,
          position,
          tasks:kanban_tasks(
            id,
            title,
            description,
            tags,
            position,
            column_id,
            created_at,
            updated_at
          )
        `)
        .eq('board_id', boardId)
        .order('position');

      // Transform the data
      const transformedColumns = columns?.map(column => ({
        ...column,
        tasks: (column.tasks || []).map(task => ({
          ...task,
          columnId: column.id,
          createdAt: new Date(task.created_at),
          updatedAt: new Date(task.updated_at),
          tags: task.tags || []
        }))
      })) || [];

      setBoard(prev => ({
        ...prev,
        id: boardId,
        title: project?.title || prev.title,
        columns: transformedColumns
      }));
    } catch (error) {
      console.error('Failed to load board:', error);
    } finally {
      setIsBoardLoading(false);
    }
  };

  useEffect(() => {
    const channel = supabase
      .channel('kanban_changes')
      .on('postgres_changes', { 
        event: '*', 
        schema: 'public', 
        table: 'kanban_tasks' 
      }, () => {
        // Handle real-time updates
        loadBoard();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  // Update functions
  const addColumn = async () => {
    console.log('Add Column clicked', { boardId: board.id, projectId });
    try {
      if (!board.id || board.id === '') {
        // Get board ID if not set
        console.log('Getting board ID for project:', projectId);
        const { data: boards } = await supabase
          .from('kanban_boards')
          .select('id')
          .eq('project_id', projectId)
          .single();

        if (!boards) {
          console.error('No board found for project');
          return;
        }
        
        // Update local board ID
        const boardId = boards.id;
        console.log('Found board ID:', boardId);
        setBoard(prev => ({ ...prev, id: boardId }));
        
        // Use this board ID for the new column
        const { data: newColumn } = await supabase
          .from('kanban_columns')
          .insert({
            board_id: boardId,
            title: 'New Column',
            position: board.columns.length
          })
          .select()
          .single();

        if (newColumn) {
          console.log('Created new column:', newColumn);
          setBoard(prev => ({
            ...prev,
            id: boardId,
            columns: [...prev.columns, { ...newColumn, tasks: [] }]
          }));
        }
      } else {
        const { data: newColumn } = await supabase
          .from('kanban_columns')
          .insert({
            board_id: board.id,
            title: 'New Column',
            position: board.columns.length
          })
          .select()
          .single();

        if (newColumn) {
          console.log('Created new column with existing board:', newColumn);
          setBoard({
            ...board,
            columns: [...board.columns, { ...newColumn, tasks: [] }]
          });
        }
      }
    } catch (error) {
      console.error('Error adding column:', error);
    }
  };

  const addTask = async (columnId: string) => {
    try {
      const { data: newTask } = await supabase
        .from('kanban_tasks')
        .insert({
          column_id: columnId,
          title: 'New Task',
          position: board.columns.find(col => col.id === columnId)?.tasks.length || 0
        })
        .select()
        .single();

      if (newTask) {
        setBoard({
          ...board,
          columns: board.columns.map(col =>
            col.id === columnId
              ? { ...col, tasks: [...col.tasks, newTask] }
              : col
          )
        });
      }
    } catch (error) {
      console.error('Error adding task:', error);
    }
  };

  // Add project selection handler
  const handleProjectSelect = async (projectId: string) => {
    try {
      setIsBoardLoading(true); // Use board-specific loading
      await loadBoard(projectId);
    } catch (error) {
      console.error('Error selecting project:', error);
    } finally {
      setIsBoardLoading(false);
    }
  };

  // Add debounced save function
  const debouncedSaveTitle = useCallback(
    debounce(async (projectId: string, newTitle: string) => {
      try {
        const { error } = await supabase
          .from('projects')
          .update({ title: newTitle })
          .eq('id', projectId);
        
        if (error) throw error;
        setSaveStatus('saved');
      } catch (error) {
        console.error('Failed to update project title:', error);
        setSaveStatus('error');
      }
    }, 750),
    [supabase]
  );

  const updateProjectTitle = (newTitle: string) => {
    if (!projectId) return;
    if (newTitle === board.title) return; // Don't update if title hasn't changed

    setSaveStatus('saving');
    setBoard(prev => ({ ...prev, title: newTitle }));
    
    // Update projects list immediately for smooth UI
    setProjects(prev => prev.map(project => 
      project.id === projectId 
        ? { ...project, title: newTitle }
        : project
    ));

    debouncedSaveTitle(projectId, newTitle);
  };

  // Add save status cleanup
  useEffect(() => {
    if (saveStatus === 'saved') {
      const timer = setTimeout(() => {
        setSaveStatus(null);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [saveStatus]);

  const createDefaultProject = async () => {
    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Create project
      const { data: project } = await supabase
        .from('projects')
        .insert([{
          title: 'New Project',
          user_id: user.id
        }])
        .select()
        .single();

      if (project) {
        // Create default view
        await supabase
          .from('project_views')
          .insert({
            project_id: project.id,
            user_id: user.id,
            type: 'table',
            title: 'Default View'
          });

        // Update local state
        setProjects([project, ...projects]);
        navigate(`/projects/${project.id}/table`);
      }
    } catch (error) {
      console.error('Error creating project:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Update the render conditions to maintain sidebar visibility
  const renderContent = () => {
    if (!projectId && projects.length === 0) {
      return (
        <div className="flex-1 flex flex-col items-center justify-center">
          {isLoading ? (
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
          ) : (
            <div className="text-center max-w-md mx-auto p-6">
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                Create Your First Project
              </h2>
              <p className="text-gray-600 dark:text-gray-400 mb-8">
                Start organizing your work with boards and tables. Add tasks, track progress, and collaborate with your team.
              </p>
              <button
                onClick={() => navigate('/projects/new')}
                className="flex items-center justify-center gap-2 w-full bg-indigo-600 text-white px-6 py-3 rounded-lg
                         hover:bg-indigo-500 transition-colors"
              >
                <Plus className="h-5 w-5" />
                Create New Project
              </button>
            </div>
          )}
        </div>
      );
    }

    if (projectId && !board.columns.length) {
      return (
        <div className="flex-1 flex flex-col items-center justify-center">
          {isBoardLoading ? (
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
          ) : (
            <>
              <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">
                Add Your First Column
              </h2>
              <p className="text-gray-600 dark:text-gray-400 mb-6">
                Get started by adding a column to your project
              </p>
              <button
                onClick={addColumn}
                className="flex items-center gap-2 bg-indigo-600 text-white px-6 py-3 rounded-lg
                       hover:bg-indigo-500 transition-colors"
              >
                <Plus className="h-5 w-5" />
                Add Column
              </button>
            </>
          )}
        </div>
      );
    }

    return (
      <>
        {/* Fixed header */}
        <div className="transition-all duration-300 ease-in-out">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center mb-6">
              <input
                type="text"
                value={board.title}
                onChange={(e) => updateProjectTitle(e.target.value)}
                className={`text-2xl font-bold bg-transparent border-none outline-none 
                           focus:outline-none focus:ring-0 hover:bg-gray-50 dark:hover:bg-gray-800/50
                           focus:bg-gray-50 dark:focus:bg-gray-800/50 rounded px-2 -ml-2
                           transition-colors duration-150 ease-in-out
                           text-gray-900 dark:text-white placeholder-gray-400 
                           dark:placeholder-gray-500`}
                id="board-title"
                name="board-title"
                aria-label="Board title"
              />
              <div className="flex items-center gap-4">
                {saveStatus && (
                  <div className={`flex items-center gap-1.5 text-xs font-medium transition-all duration-300
                    ${saveStatus === 'saved' ? 'animate-fade-out' : 'opacity-100'}`}
                  >
                    {saveStatus === 'saving' ? (
                      <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                        <div className="w-1.5 h-1.5 rounded-full bg-gray-400 dark:bg-gray-500 animate-pulse" />
                        Saving
                      </div>
                    ) : saveStatus === 'saved' ? (
                      <div className="flex items-center gap-1.5 text-gray-400 dark:text-gray-500">
                        <div className="w-1.5 h-1.5 rounded-full bg-emerald-400 dark:bg-emerald-500" />
                        Saved
                      </div>
                    ) : saveStatus === 'error' ? (
                      <div className="flex items-center gap-1.5 text-red-500 dark:text-red-400">
                        <div className="w-1.5 h-1.5 rounded-full bg-red-500 dark:bg-red-400" />
                        Failed to save
                      </div>
                    ) : null}
                  </div>
                )}
                <button
                  onClick={addColumn}
                  className="flex items-center gap-2 bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
                >
                  <Plus className="h-4 w-4" />
                  Add Column
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Board container */}
        <div className="transition-all duration-300 ease-in-out">
          <div className="w-full">
            {isBoardLoading ? (
              <div className="flex-1 flex items-center justify-center h-[calc(100vh-12rem)]">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
              </div>
            ) : (
              <KanbanView board={board} setBoard={setBoard} addTask={addTask} />
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="w-full [&~footer]:hidden">
        {renderContent()}
      </div>
    </ErrorBoundary>
  );
} 