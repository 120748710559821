import { useState } from 'react';
import { useSupabase } from '../../lib/supabase/supabase-context';
import type { Table } from '../../types/table';
import { Plus, Trash2 } from 'lucide-react';
import { TableCell } from './cells';
import { ConfirmDialog } from '../common/ConfirmDialog';

interface TableViewProps {
  board: Table;
  setBoard: (board: Table) => void;
}

export function TableView({ board, setBoard }: TableViewProps) {
  const { supabase } = useSupabase();
  const [editingColumnId, setEditingColumnId] = useState<string | null>(null);
  const [editingTaskId, setEditingTaskId] = useState<string | null>(null);
  const [deleteColumnId, setDeleteColumnId] = useState<string | null>(null);
  const [deleteTaskId, setDeleteTaskId] = useState<string | null>(null);

  const updateColumnTitle = async (columnId: string, newTitle: string) => {
    try {
      const { error } = await supabase
        .from('view_columns')
        .update({ title: newTitle })
        .eq('id', columnId);

      if (error) throw error;

      setBoard({
        ...board,
        columns: board.columns.map(col =>
          col.id === columnId ? { ...col, title: newTitle } : col
        )
      });
    } catch (error) {
      console.error('Failed to update column title:', error);
    }
    setEditingColumnId(null);
  };

  const deleteColumn = async (columnId: string) => {
    try {
      const { error } = await supabase
        .from('view_columns')
        .delete()
        .eq('id', columnId);

      if (error) throw error;

      // Update tasks to remove metadata for this column
      const updatedTasks = board.tasks?.map(task => ({
        ...task,
        metadata: Object.fromEntries(
          Object.entries(task.metadata).filter(([key]) => key !== columnId)
        )
      }));

      // Update tasks in Supabase
      if (updatedTasks?.length) {
        await Promise.all(
          updatedTasks.map(task =>
            supabase
              .from('view_items')
              .update({ metadata: task.metadata })
              .eq('id', task.id)
          )
        );
      }

      setBoard({
        ...board,
        columns: board.columns.filter(col => col.id !== columnId),
        tasks: updatedTasks
      });
    } catch (error) {
      console.error('Failed to delete column:', error);
    }
    setDeleteColumnId(null);
  };

  const deleteTask = async (taskId: string) => {
    try {
      const { error } = await supabase
        .from('view_items')
        .delete()
        .eq('id', taskId);

      if (error) throw error;

      setBoard({
        ...board,
        tasks: board.tasks?.filter(task => task.id !== taskId)
      });
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
    setDeleteTaskId(null);
  };

  const updateTaskTitle = async (taskId: string, newTitle: string) => {
    try {
      const { error } = await supabase
        .from('view_items')
        .update({ title: newTitle })
        .eq('id', taskId);

      if (error) throw error;

      setBoard({
        ...board,
        tasks: board.tasks?.map(task =>
          task.id === taskId ? { ...task, title: newTitle } : task
        )
      });
    } catch (error) {
      console.error('Failed to update task title:', error);
    }
    setEditingTaskId(null);
  };

  const updateTaskMetadata = async (taskId: string, columnId: string, value: any) => {
    try {
      const task = board.tasks?.find(t => t.id === taskId);
      if (!task) return;

      const newMetadata = {
        ...task.metadata,
        [columnId]: value
      };

      const { error } = await supabase
        .from('view_items')
        .update({ metadata: newMetadata })
        .eq('id', taskId);

      if (error) throw error;

      setBoard({
        ...board,
        tasks: board.tasks?.map(t =>
          t.id === taskId ? { ...t, metadata: newMetadata } : t
        )
      });
    } catch (error) {
      console.error('Failed to update task metadata:', error);
    }
  };

  const addTask = async () => {
    try {
      const { data: newTask, error } = await supabase
        .from('view_items')
        .insert([
          {
            view_id: board.id,
            title: 'New Task',
            position: board.tasks?.length || 0,
            metadata: {}
          }
        ])
        .select()
        .single();

      if (error) throw error;

      setBoard(prev => ({
        ...prev,
        tasks: [...(prev.tasks || []), newTask]
      }));
    } catch (error) {
      console.error('Failed to add task:', error);
    }
  };

  return (
    <>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-800 text-left group">
                <span className="text-white">Title</span>
              </th>
              {board.columns.map(column => (
                <th
                  key={column.id}
                  className="px-6 py-3 bg-gray-800 text-left group"
                >
                  <div className="flex items-center justify-between">
                    {editingColumnId === column.id ? (
                      <input
                        type="text"
                        value={column.title}
                        onChange={(e) => {
                          setBoard({
                            ...board,
                            columns: board.columns.map(col =>
                              col.id === column.id ? { ...col, title: e.target.value } : col
                            )
                          });
                        }}
                        onBlur={(e) => updateColumnTitle(column.id, e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            updateColumnTitle(column.id, column.title);
                          }
                        }}
                        className="bg-transparent border-none text-white focus:ring-2 focus:ring-indigo-500"
                        autoFocus
                      />
                    ) : (
                      <div className="flex items-center justify-between w-full group">
                        <span 
                          className="text-white cursor-pointer"
                          onClick={() => setEditingColumnId(column.id)}
                        >
                          {column.title}
                        </span>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteColumnId(column.id);
                          }}
                          className="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 
                                   transition-opacity focus:opacity-100 p-1 rounded-full
                                   hover:bg-gray-700/50"
                        >
                          <Trash2 className="h-4 w-4" />
                        </button>
                      </div>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {board.tasks?.map(task => (
              <tr key={task.id} className="group hover:bg-gray-50 dark:hover:bg-gray-800/50">
                <td className="px-6 py-3 relative">
                  {editingTaskId === task.id ? (
                    <input
                      type="text"
                      value={task.title}
                      onChange={(e) => {
                        setBoard({
                          ...board,
                          tasks: board.tasks?.map(t =>
                            t.id === task.id ? { ...t, title: e.target.value } : t
                          )
                        });
                      }}
                      onBlur={(e) => updateTaskTitle(task.id, e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          updateTaskTitle(task.id, task.title);
                        }
                      }}
                      className="bg-transparent border-none text-gray-900 dark:text-gray-100 focus:ring-2 focus:ring-indigo-500 w-full"
                      autoFocus
                    />
                  ) : (
                    <div className="flex items-center justify-between">
                      <span 
                        className="cursor-pointer text-gray-900 dark:text-gray-100 hover:text-indigo-600 dark:hover:text-indigo-400"
                        onClick={() => setEditingTaskId(task.id)}
                      >
                        {task.title}
                      </span>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteTaskId(task.id);
                        }}
                        className="text-gray-400 hover:text-red-500 opacity-0 group-hover:opacity-100 
                                 transition-opacity focus:opacity-100 p-1 rounded-full
                                 hover:bg-gray-100 dark:hover:bg-gray-700"
                      >
                        <Trash2 className="h-4 w-4" />
                      </button>
                    </div>
                  )}
                </td>
                {board.columns.map(column => (
                  <td key={column.id} className="px-6 py-3">
                    <TableCell
                      column={column}
                      value={task.metadata?.[column.id]}
                      onChange={(value) => updateTaskMetadata(task.id, column.id, value)}
                    />
                  </td>
                ))}
              </tr>
            ))}
            <tr 
              className="group hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer"
              onClick={addTask}
            >
              <td className="px-6 py-3 text-gray-400 dark:text-gray-500 font-medium">
                <span className="flex items-center gap-2">
                  <Plus className="h-4 w-4 opacity-0 group-hover:opacity-100" />
                  Add Task
                </span>
              </td>
              {board.columns.map(column => (
                <td 
                  key={column.id}
                  className="px-6 py-3"
                />
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <ConfirmDialog
        isOpen={!!deleteColumnId}
        onClose={() => setDeleteColumnId(null)}
        onConfirm={() => deleteColumnId && deleteColumn(deleteColumnId)}
        title="Delete Column"
        message="Are you sure you want to delete this column? This will remove the column and its data from all tasks. This action cannot be undone."
      />

      <ConfirmDialog
        isOpen={!!deleteTaskId}
        onClose={() => setDeleteTaskId(null)}
        onConfirm={() => deleteTaskId && deleteTask(deleteTaskId)}
        title="Delete Task"
        message="Are you sure you want to delete this task? This action cannot be undone."
      />
    </>
  );
} 